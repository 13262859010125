import React, { useState } from 'react';
import { IconButton, Divider, Typography, Popover, Dialog, FormControl, InputLabel, Select, MenuItem, TextField } from '@material-ui/core';
import APIHandler from "utils/networking/APIHandler";
import TAButton from "@components/Common/TaButton/TaButton";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CloseIcon from "@material-ui/icons/Close";

const HELP_OPTIONS = [
    "Account Management",
    "Support Request",
    "Pricing Question",
    "Billing Question",
    "Security & Data Concerns",
    "Idea/Feature Request",
    "Other"
]

const BODY_LIMIT = 5000;

export default function ResourceCenter(props) {
    const [showHelp, setShowHelp] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [body, setBody] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const open = Boolean(props.anchorEl);
    const id = open ? 'resource-center' : undefined;

    function onHelpClick() {
        props.handleClose();
        setShowHelp(true);
    }

    function onClose() {
        reset();
        setShowHelp(false)
    }

    function onSetBody(text) {
        if(text.length > BODY_LIMIT) {
            window.NotificationUtils.showError("Character limit reached");
            return;
        }
        setBody(text)
    }

    function reset() {
        setSelectedOption(null);
        setBody(null);
    }

    async function onSubmitRequest() {
        const query = {
            subject: selectedOption,
            body
        }
        setSubmitting(true)
        try {
            await APIHandler.setHelpRequest(query);
            window.NotificationUtils.showSuccess("Thank you for reaching out. Someone will contact you shortly.")
            onClose();
        }
        catch(e) {
            console.error(e);
        }
        setSubmitting(false)
    }

    function renderHelp() {
        return (
            <Dialog
                open={showHelp}
                onClose={onClose}
                id="help-dialog"
            >
                <div className='help-dialog-content'>
                <IconButton size="small" onClick={onClose} className="help-close-icon">
                    <CloseIcon />
                </IconButton>
                    <Typography variant='h5' className='mg-bt-16'>Need help or have a request?</Typography>
                    <Typography variant="caption">If you want to request anything with Xcloud, including help, features or concerns, please fill out the fields below. Someone will be in contact with you shortly.</Typography>
                    <FormControl style={{minWidth: 300}} className="mg-top-32">
                        <InputLabel id="help-select-label" shrink={true}>What type of request do you have?</InputLabel>
                        <Select
                            labelid="help-select-label"
                            id="help-select"
                            variant={'standard'}
                            value={selectedOption}
                            onChange={(e) => setSelectedOption(e.target.value)}
                        >
                            {HELP_OPTIONS?.map((option, index) => {
                                return (<MenuItem key={index} value={option}>
                                        {option}
                                    </MenuItem>)
                            })}
                        </Select>
                    </FormControl>
                    <FormControl className="fullWidth mg-top-32" id="help-body">
                        <InputLabel id="help-body-label" shrink={true}>What are the details of your request?</InputLabel>
                        <TextField labelid="help-body-label" multiline={true} value={body} onChange={(e) => onSetBody(e.target.value)} rows={5} />
                    </FormControl>
                    <div className='dispFlex justifyEnd textColorGray' style={{fontSize: 11}}>{body?.length || 0}/{BODY_LIMIT}</div>
                    <div className='fullWidth center mg-top-32'>
                        <TAButton 
                            variant="contained"
                            color="primary"
                            className='fullWidth mg-top-32'
                            onClick={onSubmitRequest}
                            isLoading={submitting}
                            loadingMessage={"Submitting..."}
                            disabled={Boolean(!body || !selectedOption)}
                        >
                            Submit Request
                        </TAButton>
                    </div>
                </div>
            </Dialog>
        )
    }

    function renderItem(title, subtitle, action) {
        return (
            <div className='rc-item alignCenter flexRow justifyBetween' onClick={action}>
                <div className='flexColumn'>
                    <Typography variant='subtitle1'>{title}</Typography>
                    <Typography variant='caption' className='textColorGray'>{subtitle}</Typography>
                </div>
                <ChevronRightIcon />
            </div>
        )
    }

    return (
        <>
            <Popover
                id={id}
                open={open}
                anchorEl={props.anchorEl}
                onClose={props.handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <div className="resource-center-content">
                    <div className='rc-header'>
                        <div className='rc-top-color' />
                        <div className='rc-title fullWidth center'>
                            <Typography variant="h5">Resource Center</Typography>
                        </div>
                    </div>
                    <div className='rc-items-container'>
                        {renderItem('Help & Feedback', 'SUBMIT YOUR REQUESTS', onHelpClick)}
                        <Divider />
                    </div>
                </div>
            </Popover>
            {renderHelp()}
        </>
    )
}