/*
 * ------------------------
 * File: /src/components/Misc/SubscriptionComponent.js
 * Project: scap-viewer
 * ------------------------
 * Copyright colortokens.com - All Rights Reserved.
 * The intellectual and technical concepts contained herein are proprietary to colortokens.com
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 * ------------------------
 */

import { Component } from "react";
import { Subject } from "rxjs";

export default class SubscriptionBase extends Component {
  constructor(props) {
    super(props);
    this.end$ = new Subject();
  }

  componentWillUnmount() {
    this.end$.next("Unmount");
    this.end$ = null;
  }
}
