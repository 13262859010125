/* eslint react/jsx-props-no-spreading: 0 */

import React from "react";
import { AuthUserInfoContext } from "../auth/hooks";

const WithAuthUserInfo = ComposedComponent => {
  const WithAuthUserInfoComp = props => {
    return (
      <AuthUserInfoContext.Consumer>
        {value => {
          return (
            <ComposedComponent
              {...props}
              initializing={value.initializing}
              authState={value.authState || null}
              logout={value.logout}
            />
          );
        }}
      </AuthUserInfoContext.Consumer>
    );
  };

  if (ComposedComponent.getInitialProps) {
    WithAuthUserInfoComp.getInitialProps = async ctx => {
      let composedInitialProps = {};
      if (ComposedComponent.getInitialProps) {
        composedInitialProps = await ComposedComponent.getInitialProps(ctx);
      }

      return {
        ...composedInitialProps
      };
    };
  }

  WithAuthUserInfoComp.displayName = `WithAuthUserInfo(${ComposedComponent.displayName})`;

  WithAuthUserInfoComp.defaultProps = {};

  return WithAuthUserInfoComp;
};

// Provides an AuthUserInfo prop to the composed component.
export default WithAuthUserInfo;
