import React, { useState } from "react";
import { AUTH_STATES } from "src/constants";
import { authAxiosInstance } from "../axios";
import APIHandler from "utils/networking/APIHandler";

// https://benmcmahen.com/using-firebase-with-react-hooks/

// Defaults to empty AuthUserInfo object.
export const AuthUserInfoContext = React.createContext();

export const useAuthUserInfo = () => {
  return React.useContext(AuthUserInfoContext);
};

// Returns a Firebase JS SDK user object.
export const useTokenAuth = onChangeUser => {
  const [state, setState] = useState({
    authState: AUTH_STATES.Unknown,
    initializing: true
  });
  const [config, setConfig] = useState(null);

  function onChange(update) {
    setState({...update});
    // Call server to update session.
    onChangeUser && onChangeUser(update, logout, doWork);
  }

  async function logout() {
    try {
      let conf = await getConfig();
      let response = await authAxiosInstance.post(
        `${conf.NG_AUTH_SERVER_URL}/user/logout`
      );
      if(typeof window !== "undefined") {
        if (response?.data?.ssoLogoutURL) {
          window.location.href = response?.data?.ssoLogoutURL;
        }
        window.localStorage.clear();
        window.sessionStorage.clear();
      }
      onChange({ authState: AUTH_STATES.LoggedOut, initializing: false })
    } catch (e) {
      if (e.response.status === 401 || e.response.status === 403) {
        if(typeof window !== "undefined") {
          window.localStorage.clear();
          window.sessionStorage.clear();
        }
        onChange({ authState: AUTH_STATES.LoggedOut, initializing: false })
      } else {
        onChange({ authState: AUTH_STATES.Unknown, initializing: false })
      }
    }
  }

  async function getConfig() {
    if(config) {
      return config;
    }
    try {
      let conf = await APIHandler.getConfig();
      setConfig(conf);
      return conf;
    }
    catch(e) {
      console.error(e);  
    }
  }

  async function doWork(tenantId) {
    try {
      let conf = await getConfig();
      if(tenantId) {
        await authAxiosInstance.post(`${conf.NG_AUTH_SERVER_URL}/token${tenantId ? "/"+tenantId : ""}`);
      }
      else {
        await authAxiosInstance.get(`${conf.NG_AUTH_SERVER_URL}/token`);
      }
      onChange({
        authState: AUTH_STATES.LoggedIn,
        initializing: false
      });
    } catch (e) {
      if (e?.response?.status === 401 || e?.response?.status === 403) {
        onChange({ authState: AUTH_STATES.LoggedOut,  initializing: false });
        return AUTH_STATES.LoggedOut;
      } else {
        onChange({ authState: AUTH_STATES.Unknown, initializing: false });
      }
    }
  }

  return {
    checkAuthState: doWork,
    ...state,
    logout
  };
};
