/* eslint react/jsx-props-no-spreading: 0 */
import React, { useState } from "react";
import { AUTH_STATES } from "../../src/constants";
import { AuthUserInfoContext, useTokenAuth } from "../auth/hooks";

const WithAuthUser = ComposedComponent => {
  const WithAuthUserComp = props => {
    const { ...otherProps } = props;

    const { checkAuthState, authState, initializing, logout } = useTokenAuth(
      props.fetchUser
    );  
    const [count, setCount] = useState(0);
  
    if(!props.userProfile && authState !== AUTH_STATES.LoggedOut && count < 2) {
      checkAuthState();
      setCount(count+1);
    }

    return (
      <AuthUserInfoContext.Provider value={{ authState, initializing, logout }}>
        <ComposedComponent {...otherProps} />
      </AuthUserInfoContext.Provider>
    );
  };

  if (ComposedComponent.getInitialProps) {
    WithAuthUserComp.getInitialProps = async ctx => {
      // Evaluate the composed component's getInitialProps().
      let composedInitialProps = {};
      composedInitialProps = await ComposedComponent.getInitialProps(ctx);

      return {
        ...composedInitialProps
      };
    };
  }

  WithAuthUserComp.displayName = `WithAuthUser(${ComposedComponent.displayName})`;

  WithAuthUserComp.defaultProps = {};

  return WithAuthUserComp;
};

// Gets the authenticated user from the Firebase JS SDK, when client-side,
// or from the request object, when server-side. Add the AuthUserInfo to
// context.
export default WithAuthUser;
