/*
 * ------------------------
 * File: /src/components/for_main/appbar.js
 * Project: scap-viewer
 * ------------------------
 * Copyright colortokens.com - All Rights Reserved.
 * The intellectual and technical concepts contained herein are proprietary to colortokens.com
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 * ------------------------
 */

import { CircularProgress } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Toolbar from "@material-ui/core/Toolbar";
import ArrowDown from "@material-ui/icons/KeyboardArrowDown";
import Router from "next/router";
import React from "react";
import AuthBar from "./AppbarAuthContainer";
import { TitleContext } from "src/context/title/title";
import SubscriptionComponent from "@components/Misc/SubscriptionComponent";
import { takeUntil } from "rxjs/operators";

export default class Header extends SubscriptionComponent {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      title: props.initialTitle,
      headerOpen: false
    };
  }

  componentDidMount() {
    let onNext = event => {
      this.setState(
        {
          title: event.value
        },
        this.setDocumentTitle
      );
    };
    let onError = e => {
      console.error("Error in stream", e);
    };

    this.props.titleStream
      .pipe(takeUntil(this.end$))
      .subscribe(onNext, onError);
  }

  setDocumentTitle = () => {
    document.title = this.state.title;
  };

  routeTo = pathToRoute => {
    Router.push(pathToRoute);
  };

  setAnchorEl = element => {
    this.setState({
      anchorEl: element
    });
  };

  handleMenu = event => {
    this.setAnchorEl(event.currentTarget);
  };

  handleClose = () => {
    this.setAnchorEl(null);
  };

  signIn = () => {
    window.location.href = `/auth/login?signupRedirectURL=https://${window.location.hostname}/xcloud/auth/signup`;
  };

  openAccount = () => {
    this.setAnchorEl(null);
    this.setState({
      title: "Account"
    });
    this.routeTo("/xcloud/account");
  };

  logout = () => {
    this.setAnchorEl(null);
    Router.replace("/xcloud/logout");
  };

  toggleheader = () => {
    this.setState({
      headerOpen: !this.state.headerOpen
    });
  };

  render() {
    let titleProvider = this.context;
    return (
      <AppBar
        id="homePageHeader"
        color="inherit"
        position="fixed"
        className={
          this.props.classes.appBar +
          ` ${this.state.headerOpen && !this.props.userProfile ? "open" : ""}`
        }
        elevation={1}
        suppressHydrationWarning
      >
        <Toolbar id="headerToolBar">
          <div id="logoAndIconContainer">
            <div className="logoContainer">
              {/* <Logo variant={"fullLogoBlue"} /> */}
              {/* <Typography className="dashboard-title">
                  {titleProvider.title}
                </Typography> */}
            </div>

            <IconButton id="headerOpener" onClick={this.toggleheader}>
              <ArrowDown />
            </IconButton>
          </div>

          {this.props.checkingCache ? (
            <CircularProgress size={24} color="primary" />
          ) : (
            <>
              {/* hide top nav */}
              <div className="desktopNavigation">
                {this.renderTabs(this.props)}
              </div>

              <AuthBar
                {...this.props}
                primary={this.props.isHomePage}
                userProfile={this.props.userProfile}
                showCredentials={true}
              />
            </>
          )}
        </Toolbar>
      </AppBar>
    );
  }

  renderTabs(props) {
    return (
      <Tabs
        indicatorColor="primary"
        value={isNaN(props.selectedTab) ? false : props.selectedTab}
        variant="standard"
        scrollButtons="off"
      >
        <Tab
          className="MuiButtonBase-root MuiButton-root MuiButton-text landingPageHeaderButton MuiButton-colorInherit"
          label="Home"
          onClick={() => {
            Router.push("/xcloud/public", "/public", { shallow: true });
          }}
        />
        <Tab
          className="MuiButtonBase-root MuiButton-root MuiButton-text landingPageHeaderButton MuiButton-colorInherit"
          label="Solutions"
          onClick={() => {
            Router.push("/xcloud/solutions", "/solutions", { shallow: true });
          }}
        />
        <Tab
          className="MuiButtonBase-root MuiButton-root MuiButton-text landingPageHeaderButton MuiButton-colorInherit"
          label="About"
          onClick={() => {
            Router.push("/xcloud/about", "/about", { shallow: true });
          }}
        />
        <Tab
          className="MuiButtonBase-root MuiButton-root MuiButton-text landingPageHeaderButton MuiButton-colorInherit"
          label="Insights"
          onClick={() => {
            Router.push("/xcloud/insights", "/insights", { shallow: true });
          }}
        />
        <Tab
          className="MuiButtonBase-root MuiButton-root MuiButton-text landingPageHeaderButton MuiButton-colorInherit"
          label="Contact Us"
          onClick={() => {
            Router.push("/xcloud/contact-us", "/contact-us", { shallow: true });
          }}
        />
      </Tabs>
    );
  }
}

Header.contextType = TitleContext;
