import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import { Typography, Chip, Divider, Switch } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Router from "next/router";
import React, { Component } from "react";
import { ThemeContext } from "src/context/theme/theme";
import APIHandler from "../../../utils/networking/APIHandler";

import {
  THEME_TYPES,
  SIGN_UP_TYPES
} from "src/constants";

export const ACCOUNT_TYPES = {
  PARTNER: "Partner Plan",
  STANDARD: "Standard Plan"
}

export function getAccountType(tenantDetails) {
  if (tenantDetails?.signupType === SIGN_UP_TYPES.PARTNER_CLOUD) {
    return ACCOUNT_TYPES.PARTNER
  }
  if (tenantDetails?.awsDetails?.dimensionPlan) {
    return tenantDetails.awsDetails.dimensionPlan;
  }
  return ACCOUNT_TYPES.STANDARD
}

export default class AppBarButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      tenantsList: null
    };
  }

  async switchTenant(tenantId) {
    if(tenantId === this.state.tenantsList?.current?.tenantID) {
      return;
    }
    window.securevia.updateCookie(tenantId);
    setTimeout(() => {
      Router.reload("/xcloud/home");
      window.securevia.getTenantsList();
    }, 1000)
  }

  handleProfileMenuOpen = event => {
    this.setState({
      anchorEl: event.currentTarget
    });
  };

  handleMenuClose = () => {
    this.setState({
      anchorEl: null
    });
  };

  handleProfileClick = () => {
    Router.push("/xcloud/profile");
  };

  routeTo = pathToRoute => {
    window.location.href = pathToRoute;
  };

  logout = () => {
    this.handleMenuClose();
    Router.replace("/xcloud/logout");
    this.context?.type === THEME_TYPES.DARK && this.context?.toggleTheme(THEME_TYPES.LIGHT)
  };

  renderContent = () => {
    if (!this.props.userProfile) {
      return (
        <div id={"headerFreeButton"}>
          <Button
            className="landingPageHeaderButton"
            color="inherit"
            onClick={() => this.routeTo(`/auth/login?signupRedirectURL=https://${window.location.hostname}/xcloud/auth/signup`)}
          >
            Sign In
          </Button>
        </div>
      );
    } else {
      let name = this.props.userProfile && this.props.userProfile.firstName;
      return (
        <div className={this.props.userProfile ? "profile-icon-container" : null}>
          <div className="user-profile-container" onClick={this.handleProfileMenuOpen}>
            <Avatar
              className="avatar user-profile"
              alt={name}
              src={
                this.props.userProfile && this.props.userProfile.profilePicURL
              }
            ></Avatar>
          </div>
        </div>
      );
    }
  };

  renderAvatarContainer = () => {
    let user = this.props.userProfile;
    return (
      <div id="appBarAvatarContainer">
        <Typography variant="body2" className="mg-bottom-4 textColorGray mg-top-8">
          {user?.email || "-"}
        </Typography>
        <div id="accountTierBadge">
          <Chip
            label={getAccountType(user?.tenantDetails)}
            color="primary"
            size="small"
            variant="outlined"
          />
        </div>
      </div>
    );
  };

  renderThemeSwitcher() {
    return (
      <div className="themeSwitcher">
        <Typography>Dark Mode</Typography>
        <Switch
          checked={Boolean(this.context?.type === THEME_TYPES.DARK)}
          onChange={() => this.context?.toggleTheme()}
          name="themeSwitch"
          color="primary"
        />
      </div>
    );
  }

  renderTenants() {
    return this.props.tenantsList.tenants.map((tenant, index) => {
      let selected = false;
      if(tenant.tenantID === this.props.tenantsList?.current?.tenantID) {
        selected = true
      }
      return (
        <div key={index} className={`tenant-list-item ${selected ? "selected-tenant" : ""}`} onClick={() => this.switchTenant(tenant.tenantID)}>
          {tenant.name}
        </div>
      )
    })
  }

  renderOrganizationPicker() {
    return (
      <div id="org-picker">
        <div className="org-header">Organization</div>
        <div className="org-list">
          {this.props.tenantsList?.tenants?.length ? 
            this.renderTenants() : 
            null
          }
        </div>
      </div>
    )
  }

  render() {
    let options = [];
    options.push(
      <MenuItem id="logout" key="logout" onClick={this.logout}>
        Logout
      </MenuItem>
    );
    return (
      <React.Fragment>
        {this.renderContent()}
        <Menu
          anchorEl={this.state.anchorEl}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          id="primary-search-account-menu-mobile"
          keepMounted
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleMenuClose}
          className="noElevationBorder"
        >
          {this.props.userProfile && this.renderAvatarContainer()}
          {this.props.userProfile && this.renderOrganizationPicker()}
          {this.props.userProfile && <Divider className="mg-bottom-8" />}
          {this.props.userProfile && options}
          {/* <Divider />
          {this.renderThemeSwitcher()} */}
        </Menu>
      </React.Fragment>
    );
  }
}

AppBarButton.contextType = ThemeContext;
