import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Badge from "@material-ui/core/Badge";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useState } from "react";
import { Subject } from "rxjs";
import { shareReplay } from "rxjs/operators";
import Header from "@components/Common/Header/Header";
import Logo from "@components/Common/Logo";
import { EVENT_STREAMS, ROLES, DISABLED_FEATURES } from "src/constants";
import APIHandler from "utils/networking/APIHandler";
import Typography from "@material-ui/core/Typography";
import ResourceCenter from "./resourceCenter";

const drawerWidth = 80;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column"
  },
  drawer: {
    [theme.breakpoints.up("xlg")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    marginLeft: drawerWidth,
    // width: `calc(100% - ${drawerWidth}px)`
    [theme.breakpoints.up("xlg")]: {
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up("xlg")]: {
      display: "none"
    }
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    padding: 0
  },
  mainContent: {
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingBottom: 0,
    minHeight: "100vh"
  },
  title: {
    flexGrow: 1
  },
  menuIcon: {},
  footer: {
    width: "100%",
    height: 55,
    padding: "16px 26px",
    marginTop: 20,
    marginBottom: 0,
    backgroundColor: "#e6e6e6",
    color: "#29353c !important",
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex"
  },
  footerLinks: {
    color: "#29353c !important",
    textDecoration: "none !important"
  }
}));

// actual side nav
let NAV_ITEMS = [
  {
    icon: "dashboard",
    text: "Dashboard",
    path: "/xcloud/home",
    condition: user => {
      return (
        user &&
        (user.role === ROLES.ADMIN ||
          user.role === ROLES.OPERATOR ||
          user.role === ROLES.VIEWER)
      );
    },
    isDisabled: () => {
      return false;
    }
  },
  {
    icon: "risks",
    text: "Risks",
    path: "/xcloud/risks",
    condition: user => {
      return (
        user &&
        (user.role === ROLES.ADMIN ||
          user.role === ROLES.OPERATOR ||
          user.role === ROLES.VIEWER)
      );
    },
    isDisabled: user => {
      return (
        user &&
        (user.role === ROLES.ADMIN ||
          user.role === ROLES.OPERATOR ||
          user.role === ROLES.VIEWER) &&
        !user.hasResources
      );
    }
  },
  {
    icon: "resources",
    text: "Resources",
    path: "/xcloud/resources",
    condition: user => {
      return (
        user &&
        (user.role === ROLES.ADMIN ||
          user.role === ROLES.OPERATOR ||
          user.role === ROLES.VIEWER)
      );
    },
    isDisabled: user => {
      return (
        user &&
        (user.role === ROLES.ADMIN ||
          user.role === ROLES.OPERATOR ||
          user.role === ROLES.VIEWER) &&
        !user.hasResources
      );
    }
  },
  {
    icon: "compliance",
    text: "Compliance",
    path: "/xcloud/compliance-reports",
    condition: user => {
      return (
        user &&
        (user.role === ROLES.ADMIN ||
          user.role === ROLES.OPERATOR ||
          user.role === ROLES.VIEWER)
      );
    },
    isDisabled: user => {
      return (
        user &&
        (user.role === ROLES.ADMIN ||
          user.role === ROLES.OPERATOR ||
          user.role === ROLES.VIEWER) &&
        !user.hasResources
      );
    }
  },
  {
    icon: "settings",
    text: "",
    path: "/xcloud/settings/cloud-accounts",
    badge: user => {
      return user.totalCount;
    },
    condition: user => {
      return (
        user && (user.role === ROLES.ADMIN || user.role === ROLES.OPERATOR)
      );
    },
    isDisabled: () => {
      return false;
    }
  },
  {
    icon: "get_app",
    text: "Downloads",
    path: "/xcloud/download",
    condition: () => {
      return false;
    },
    isDisabled: () => {
      return false;
    }
  },
  {
    icon: "check_circle",
    text: "IT Config",
    path: "/xcloud/definitions",
    condition: () => {
      return false;
    },
    isDisabled: () => {
      return false;
    }
  },
  {
    icon: "equalizer",
    text: "CVEs",
    path: "/xcloud/cves",
    condition: () => {
      return false;
    },
    isDisabled: () => {
      return false;
    }
  },
  {
    icon: "assignment",
    text: "Reports",
    path: "/xcloud/reports",
    condition: () => {
      return false;
    },
    isDisabled: () => {
      return false;
    }
  },
  {
    icon: "book",
    text: "Authoring",
    path: "/xcloud/authoring",
    condition: (user, disabledFeatures) => {
      if (
        disabledFeatures.includes(DISABLED_FEATURES.CLOUD) ||
        disabledFeatures.includes(DISABLED_FEATURES.AUTHORING)
      ) {
        return false;
      }
      return (
        user && (user.role === ROLES.ADMIN || user.role === ROLES.OPERATOR)
      );
    },
    isDisabled: () => {
      return false;
    }
  }
];

let noPaddingWhiteList = [
  "/dashboard",
  "/home",
  "/risks",
  "/resources",
  "/compliance-reports",
  "/settings"
];

const getDisabledFeaures = async () => {
  let config = await APIHandler.getConfig();
  if (config && config.DISABLED_FEATURES) {
    return config.DISABLED_FEATURES;
  }
  return [];
};

const MOBILE_NAV_ITEMS = [
  {
    icon: "home",
    text: "Home",
    path: "/home"
  },
  {
    icon: "emoji_objects",
    text: "Solutions",
    path: "/solutions"
  },
  {
    icon: "info",
    text: "About",
    path: "/about"
  },
  {
    icon: "book",
    text: "Insights",
    path: "/insights"
  },
  {
    icon: "chat_bubble",
    text: "Contact Us",
    path: "/contact-us"
  }
];

const titleStream = new Subject().pipe(shareReplay());
let eventStreams = {};
for (let key of Object.keys(EVENT_STREAMS)) {
  eventStreams[EVENT_STREAMS[key]] = new Subject().pipe(shareReplay(1));
}

function getPath(path = "") {
  let index = path.indexOf("?");
  let exactRoute = index === -1 ? path : path.substring(0, index);
  index = path.lastIndexOf("/");
  let firstIndex = path.indexOf("/");
  if (index >= 0 && firstIndex !== index) {
    exactRoute = index === -1 ? path : path.substring(index, exactRoute.length);
  }

  return exactRoute;
}

function getSelectedTab(path) {
  let index = MOBILE_NAV_ITEMS.findIndex(item => item.path === path);
  return index >= 0 ? index : false;
}

function getSelectedListItem(path) {
  let index = NAV_ITEMS.findIndex(item => {
    const itemBasePath = getPath(item.path);
    return itemBasePath === path;
  });
  return index >= 0 ? index : false;
}

function checkNoPaddingWhitelist(path = "") {
  return noPaddingWhiteList.includes(path);
}

export default function MiniDrawer(props) {
  const classes = useStyles();
  const router = useRouter();
  const [open, setOpen] = React.useState(false);
  const [RCAnchor, setRCAnchor] = useState(null);
  const [disabledFeatures, setDisabledFeatures] = React.useState([]);

  React.useEffect(() => {
    getDisabledFeaures().then(res => {
      setDisabledFeatures(res);
    });
  }, []);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const onClickCloseButton = () => {
    setOpen(!open);
  };

  let path = getPath(props.router.asPath);
  let selectedListItem = getSelectedListItem(path);
  let selectedTab = getSelectedTab(path);
  let noPaddingView = checkNoPaddingWhitelist(path);

  let defaultRoute = "/xcloud/home";

  if (
    props.userProfile?.hasResources ||
    props.userProfile?.role === ROLES.VIEWER
  ) {
    defaultRoute = "/xcloud/home";
  } else {
    defaultRoute = "/xcloud/cloud-accounts";
  }

  NAV_ITEMS = NAV_ITEMS.map(navItem => {
    if (navItem.icon === "dashboard") {
      navItem.path = defaultRoute;
    }
    return navItem;
  });

  function handleRCClose() {
    setRCAnchor(null);
  }

  return (
    <div
      className={`${classes.root} ${
        router.asPath.includes("reports") ? "reportsContainer" : ""
      }`}
      suppressHydrationWarning
    >
      <Header
        {...props}
        selectedTab={selectedTab}
        initialTitle={"IT Configuration Checks"}
        titleStream={titleStream}
        handleDrawerToggle={handleDrawerToggle}
        classes={classes}
      />
      <Drawer
        className="sideNav"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box"
          }
        }}
        variant="permanent"
        anchor="left"
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <Typography variant="h6" className="sidenav-logo-holder">
          <Logo variant={"fullLogoBlue"} area="sidenav" />
        </Typography>
        <List className="drawerList navList">
          {NAV_ITEMS.filter(navItem => {
            if (navItem.condition) {
              return navItem.condition(props.userProfile, disabledFeatures);
            } else {
              return true;
            }
          }).map((navItem, index) => {
            if (navItem.text === "divider") {
              return (
                <Divider
                  className="side-nav-divider"
                  key={navItem.text}
                  style={{ margin: "8px 0", height: 2 }}
                />
              );
            }

            return (
              <div
                className={`drawerNav ${navItem.icon} ${
                  navItem.isDisabled &&
                  navItem.isDisabled(props.userProfile, disabledFeatures)
                    ? "disabled"
                    : ""
                }`}
                key={navItem.path}
              >
                <Link shallow={true} key={navItem.text} href={navItem.path}>
                  <Badge
                    className="settings-badge"
                    badgeContent={
                      navItem?.badge ? navItem?.badge(props.userProfile) : 0
                    }
                    color="primary"
                  >
                    <ListItem selected={index === selectedListItem}>
                      <div className="side-nav-icon-wrapper">
                        <div
                          className={`${navItem.icon} side-nav-item-wrapper`}
                        >
                          <i
                            className={`${navItem.icon} nav-icon side-nav-icon`}
                          ></i>
                        </div>
                      </div>
                      <ListItemText
                        className="nav-icon-text"
                        style={{
                          display: "block",
                          color: "#fff",
                          textAlign: "center",
                          fontSize: 12
                        }}
                        primary={navItem.text}
                      />
                    </ListItem>
                  </Badge>
                </Link>
              </div>
            );
          })}
          <div
            className="pendo-placeholder-wrapper"
            onClick={e => setRCAnchor(e.currentTarget)}
          >
            <i className="pendo-placeholder nav-icon side-nav-icon"></i>
          </div>
          <ResourceCenter anchorEl={RCAnchor} handleClose={handleRCClose} />
        </List>
      </Drawer>

      <main
        className={`${classes.content}`}
        style={{ marginLeft: 80, minHeight: "100vh" }}
      >
        <div className={classes.toolbar} />
        <div className={`rootBox`}>
          {props.children({ ...props, eventStreams, titleStream })}
        </div>
        {/* TODO commented footer */}
        {/* <Footer showSocialIcons={true} /> */}
      </main>
    </div>
  );
}
