import React, { Component } from "react";
import Link from "next/link";
import { LOCAL_STORAGE_KEYS, CLOUD_USER_TYPES, ROLES } from "src/constants";

export default class Logo extends Component {
  renderSwitch(data) {
    switch (data) {
      case "fullLogoWhite":
        return "/xcloud/assets/img/colortokens.svg";
      default:
        return "/xcloud/assets/img/colortokens.svg";
    }
  }

  render() {
    let routeTo = "/xcloud/home";
    if (typeof window !== "undefined") {
      let storedUser = window.localStorage.getItem(
        LOCAL_STORAGE_KEYS.LAST_USER
      );
      storedUser = JSON.parse(storedUser);
      if (storedUser && CLOUD_USER_TYPES.includes(storedUser.signupType)) {
        if (storedUser.hasResources || storedUser?.role === ROLES.VIEWER) {
          routeTo = "/xcloud/home";
        } else {
          routeTo = "/xcloud/cloud-accounts";
        }
      }
    }

    return (
      <Link href={routeTo} legacyBehavior>
        <a
          className={this.props.area ? "sidenavLogoContainer" : "logoContainer"}
        >
          <img
            alt="ColorTokens"
            className="logo"
            src={this.renderSwitch(this.props.variant)}
          />
        </a>
      </Link>
    );
  }
}
