import React from "react";
import DashboardView from "src/views/dashboard/dashboard";
import addAuth from "./addAuth";
import Spinner from "@material-ui/core/CircularProgress";

const DashboardWrapper = (ConsumedComponent, requiresAuth, requiresVerification) => {
  const Dashboard = props => {
    if (typeof window === "undefined") {
      return (
        <div />
      )
    }
    return (
      <>
        <DashboardView {...props}>
          {compProps => {
            return <ConsumedComponent {...props} {...compProps} />;
          }}
        </DashboardView>
      </>
    );
    
  };

  if (ConsumedComponent.getInitialProps) {
    Dashboard.getInitialProps = async ctx => {
      let composedProps = {};
      if (ConsumedComponent.getInitialProps) {
        composedProps = await ConsumedComponent.getInitialProps(ctx);
      }
      return {
        ...composedProps
      };
    };
  }

  Dashboard.defaultProps = {
    AuthUserInfo: null
  };

  return addAuth(Dashboard, requiresAuth, requiresVerification);
};

export default DashboardWrapper;
