import Router from "next/router";
import React from "react";
import withAuthUser from "./withAuthUser";
import withAuthUserInfo from "./withAuthUserInfo";
import { AUTH_STATES } from "src/constants";

const AddAuth = (ConsumedComponent, require = true) => {
  const Index = props => {
    React.useEffect(() => {
      if (props.authState !== AUTH_STATES.LoggedIn && require && !props.initializing) {
        let route = "signup";
        Router.push(`/xcloud/auth/${route}?route=` + Router.asPath.replace("/", ""));
      }
    }, [props.initializing]);

    return <ConsumedComponent {...props} />;
  };

  if (ConsumedComponent.getInitialProps) {
    Index.getInitialProps = async ctx => {
      let composedInitialProps = {};
      if (ConsumedComponent.getInitialProps) {
        composedInitialProps = await ConsumedComponent.getInitialProps(ctx);
      }
      return {
        ...composedInitialProps
      };
    };
  }

  // Use `withAuthUser` to get the authed user server-side, which
  // disables static rendering.
  // Use `withAuthUserInfo` to include the authed user as a prop
  // to your component.
  return withAuthUser(withAuthUserInfo(Index));
};

export default AddAuth;
